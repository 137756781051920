// pages/patient.js
import React from "react"
import { graphql　} from "gatsby"
import Layout from "../../components/layout";
import SEO from "../../components/seo"
import Image from '../../components/image2';

const post = props => {
const post = props.data.microcmsPhoto // ㊟allMicrocmsArticleでない

 return (
   <Layout>
     <SEO title={post.title} image={post.list.url} />
     <div className="p_photo contents padding">
       <div className="p_photo_inner contents_inner inner_pc_tb">
         <div className="p_page_head inner">
           <h1 className="p_page_head_ttl">{post.title}</h1>
           <p className="p_page_head_subttl">{post.subttl}</p>
         </div>
         <div className="p_photo_box">
           {post.photo.map(item => {
             return (
             <div className="p_photo_block" key={item.image.url}>
               <Image post={item.image.url} alt={post.title} size={["1500","1200","900"]} fade={true}/>
             </div>
             )
           })}
         </div>
       </div>
     </div>
   </Layout>
 )
}

export default post


export const query = graphql`
 query($slug: String!) {
   microcmsPhoto(slug: { eq: $slug }) {
    slug
    category {
      category
      id
    }
    subttl
    title
    list {
      url
    }
    photo {
      fieldId
      image {
        url
      }
    }
   }
 }
`